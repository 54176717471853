import router from '../router'
import store from '../store';

/* タブを複数開いていた場合を想定
   ログアウトした時に別タブも同期をとってログアウトする制御 */
window.addEventListener('storage', (event) => {
    if (event.key === 'isAuthenticated' && event.newValue === 'false' || event.key === 'isTokenExpired' && event.newValue === 'true') {
        store.dispatch('clearregisterdata');
        store.dispatch('logout');
        router.push('/login');
    }
  });