import { createRouter, createWebHistory } from 'vue-router';
// import store from '@/store'; // Vuexストアをインポート
import LoginPage from '@/components/LoginPage';
import DashboardPage from '@/components/DashboardPage';
import RegistrationComplete from '@/components/RegistrationComplete';
import VideoManagement from '@/components/VideoManagement';
import ArticleManagement from '@/components/ArticleManagement';
import ChannelManagement from '@/components/ChannelManagement';
import ArticleSettings from '@/components/ArticleSettings';
import ArticlePreview from '@/components/ArticlePreview';
import AccountCreation from '@/components/AccountCreation';
import AccountCreated from '@/components/AccountCreated';
import { isLogin } from '../composables/userCheckAuth'

const routes = [
  {
      path:'/',
      name:'Dashboard',
      component: DashboardPage,
      meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage, //ログインページのため、非ログイン時の制御はしない。
  },
  {
    path: '/registration-complete',
    name: 'RegistrationComplete',
    component: RegistrationComplete,
    meta: { requiresAuth: true }
  },
  {
    path: '/video-management',
    name:'VideoManagement',
    component: VideoManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/article-management',
    name:'ArticleManagement',
    component: ArticleManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/channel-management',
    name:'ChannelManagement',
    component: ChannelManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/articles/settings/:id',
    name: 'ArticleSettings',
    component: ArticleSettings,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/articles/settings/:id/preview',
    name:'ArticlePreview',
    component: ArticlePreview,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/account-creation',
    name: 'AccountCreation',
    component: AccountCreation //アカウント作成ページのため、非ログイン時の制御はしない。
  },
  {
    path: '/account-created',
    name: 'AccountCreated',
    component: AccountCreated,
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*', // すべての未定義のパスにマッチする
    name: 'NotFound',
    component: () => import('@/components/NotFound.vue'), // 遅延ロード
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLogin()) {
      next({ path: '/login' });
    }else {
      next();
    }
  } else {
    next();
  }
});

export default router;
