import store from '../store';

export function isLogin() {

    const isAuthenticated = store.state.isAuthenticated;
    const isTokenExpired = store.state.isTokenExpired;

    if (!isAuthenticated || isTokenExpired) {
        return false;
    } else {
        return true;
    }
}