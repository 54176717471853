import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles.css'
import router from './router'
import store from './store';

import vue3GoogleLogin from 'vue3-google-login'

import vuetify from "./plugins/vuetify";

import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';

import './plugins/axios'; // Axiosインターセプターの設定
import './plugins/storage';

// アプリケーションの初期化
const app = createApp(App);

app
  .use(router)
  .use(store)
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,// クライアントID
    scope: 'email profile openid https://www.googleapis.com/auth/youtube.readonly'
  })
  .use(vuetify)
  .directive('tooltips', Tooltip)
  .directive('Button', Button)
  .mount('#app')


