<template>
    <div class="flex flex-col items-center justify-center min-h-screen p-8 w-full mx-auto bg-white rounded-lg shadow-md">
        <h1 class="text-2xl font-bold text-center text-gray-800 mb-5">ページが見つかりませんでした</h1>
        <img :src="notfoundIcon" alt="Not Found" class="mb-5">
        <p class="text-gray-600 text-center mb-8">お探しのページは移動もしくは削除された可能性があります。</p>
        <button @click="goBack" class="border border-gray-400 text-gray-800 px-4 py-2 rounded hover:bg-gray-100">前のページに戻る</button>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import notfoundIcon from '@/assets/404.svg';

export default {
    setup() {
        const router = useRouter();

        function goBack() {
            router.go(-1); // ブラウザの履歴を1つ戻る
        }

        return {
            notfoundIcon,
            goBack
        };
    }
}
</script>

<style scoped>
/* ここに追加のスタイリングを記述できます */
</style>
