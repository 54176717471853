<template>

<div v-if="!isPreviewPage">
    <div class="flex flex-col min-h-screen bg-gray-100">
        <header class="bg-white shadow">

            <nav class="mx-auto px-4 py-3 flex justify-between items-center">

                <!-- ナビゲーション左側要素 -->
                <!-- メニューボタン（SP表示時のみ表示） -->
                <div v-if="!isLoginRelatedPage" ref="navLeftDiv" class="md:hidden">
                    <button @click="toggleMenu" class="text-emerald-600 hover:text-emerald-700">
                        <!-- メニューアイコン SVG -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <!-- ナビゲーション左側または中央要素 -->
                <!-- ロゴ（常に表示、SPでは中央に配置） -->
                <div class="flex-grow md:flex-grow-0 md:flex md:justify-start">
                <router-link to="/" class="text-emerald-600 hover:text-emerald-700"> <img :src="logo" alt="Logo" class="h-8 mx-auto md:mx-0" /></router-link>
                </div>

                <!-- ナビゲーション右側要素 -->
                <div ref="navRightDiv" class="flex justify-between items-center nav-right-div">
                    <!-- ログインボタン（常に表示、右端に配置） -->
                    <div v-if="!isLoginRelatedPage" class="flex items-center logout-div">
                        <a href="#" @click.prevent="$refs.logoutBtn.click()" class="logout-icon"><img src="@/assets/loginIcn.svg" class="logout-icon cursor-pointer"/></a>
                        <button @click="handleAuthAction" ref="logoutBtn" class="flex text-gray-500 hover:text-gray-600 hidden md:inline logout-text">
                            {{ authButtonText }}
                        </button>
                    </div>
                    <!-- ヘルプ -->
                    <div class="flex items-center help-div">
                        <a href="#" @click.prevent="$refs.helpLink.click()" class="help-icon"><img src="@/assets/helpIcn.svg" class="help-icon cursor-pointer"/></a>
                        <a href="https://note.com/livedoor_echoes/m/m1d8d4ed94dc8" ref="helpLink" target="_blank" class="text-gray-500 hover:text-gray-600 hidden md:inline help-text">ヘルプ</a>
                    </div>
                </div>
            </nav>
        </header>

        <!-- メインコンテンツ -->
        <div class="flex flex-col md:flex-row flex-1 ">
            <!-- メニュー -->
            <aside v-if="!isLoginRelatedPage" :class="{'block w-full': isMenuOpen, 'hidden': !isMenuOpen}" class="md:block md:w-1/5 pt-4 pl-4 md:pl-8">

                <ul class="bg-transparent">
                    <li class="group">
                        <router-link to="/video-management" class="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-200 group-active:bg-gray-200" @click="closeMenu" active-class="bg-gray-200 font-bold">

                            <img :src="videoIcn" class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">

                            <span>動画管理</span>
                        </router-link>
                    </li>
                    <li class="group">
                        <router-link to="/article-management" class="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-200 group-active:bg-gray-200" @click="closeMenu" active-class="bg-gray-200 font-bold">
                            <img :src="articleIcn" class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <span>記事管理</span>
                        </router-link>
                    </li>
                    <li class="group">
                        <router-link to="/channel-management" class="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-200 group-active:bg-gray-200" @click="closeMenu" active-class="bg-gray-200 font-bold">
                            <img :src="channelIcn" class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <span>チャンネル管理</span>
                        </router-link>
                    </li>
                </ul>
            </aside>
            <div class="w-full mb-20">
                <router-view />
            </div>
        </div>
        <footer class="p-4 flex flex-wrap justify-between items-center text-xs bg-transparent fixed bottom-0 w-full bg-white">
            <div class="flex flex-wrap gap-4 justify-center md:justify-start">
                <a href="https://livedoor.co.jp/" target="_blank" class="text-gray-500 hover:text-gray-600">運営会社</a>
                <a href="https://www.livedoor.com/rules/" target="_blank" class="text-gray-500 hover:text-gray-600">利用規約</a>
                <a href="https://www.livedoor.com/privacy/" target="_blank" class="text-gray-500 hover:text-gray-600">プライバシーポリシー</a>
            </div>
            <div>© livedoor</div>
        </footer>
    </div>
</div>
<div v-if="isPreviewPage">
    <div class="flex flex-col min-h-screen bg-gray-100">
        <!-- メインコンテンツ -->
        <div class="flex flex-col md:flex-row flex-1 ">
            <div class="w-full mb-20">
                <router-view />
            </div>
        </div>
    </div>
</div>

</template>



<script>
import {
    onMounted,
    computed,
    ref,
    onBeforeUnmount,
} from 'vue';
import {
    useStore
} from 'vuex';
import {
    useRouter,
    useRoute
} from 'vue-router';
import logo from '@/assets/logo.svg';

import videoIcn from '@/assets/videoIcn.svg';
import articleIcn from '@/assets/articleIcn.svg';
import channelIcn from '@/assets/channelIcn.svg';

export default {
    setup() {
        const isMenuOpen = ref(false);

        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
        };

        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const isAuthenticated = computed(() => store.state.isAuthenticated);
        const isTokenExpired = computed(() => store.state.isTokenExpired);
        const authButtonText = computed(() => {
            if (!isAuthenticated.value) return 'ログイン/新規登録';
            return isTokenExpired.value ? '再ログイン' : 'ログアウト';
        });

        // wacth使うかイベント毎に行うか（ログインチェック）
        // ロゴはcopilotにナビゲーションの自動配置について質問する。

        const handleAuthAction = () => {
            if (!isAuthenticated.value || isTokenExpired.value) {
                router.push('/login');
            } else {
                store.dispatch('clearregisterdata');
                store.dispatch('logout');
                router.push({
                    name: 'Login'
                });
            }
        };

        function closeMenu() {
            isMenuOpen.value = false;
        }

        // 現在のページがログイン関連ページかどうかを判断するcomputedプロパティ
        const isLoginRelatedPage = computed(() => {
            return ['Login', 'RegistrationComplete', 'AccountCreation' ,'AccountCreated'].includes(route.name);
        });

        // 現在のページがプレビューページかどうかを判断するcomputedプロパティ
        const isPreviewPage = computed(() => {
            return ['ArticlePreview'].includes(route.name);
        });

        onMounted(() => {
            const frontEnv = process.env.VUE_APP_FRONT_ENV;
            if(frontEnv === 'development'){
                document.title = "ECHOES（dev）";
            }
        })

        const navRightDiv = ref(null);
        const navLeftDiv = ref(null);
        const navRightWidth = ref(0);
        const BREAK_POINT_MD = 768;

        // ナビゲーション内の左右の要素のwidthを整え、ロゴを中央に配置する（ブレイクポイント（768px））
        const updateWidths = () => {
            if(navLeftDiv.value == null){
                return;
            }
            if (window.innerWidth < BREAK_POINT_MD) {
                navRightWidth.value = navRightDiv.value.offsetWidth;
                navLeftDiv.value.style.width = `${navRightWidth.value}px`;
            } else {
                navLeftDiv.value.style.width = 'auto';
            }
        };

        onMounted(() => {
            updateWidths();
            window.addEventListener('resize', updateWidths);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateWidths);
        });

        return {
            isMenuOpen,
            closeMenu,
            toggleMenu,
            authButtonText,
            handleAuthAction,
            logo,
            videoIcn,
            articleIcn,
            channelIcn,
            isLoginRelatedPage,
            isPreviewPage,
            navRightDiv,
            navLeftDiv,
        };
    }
};
</script>

<style>
body {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Arial, Meiryo, sans-serif;
    color: #374151;
}
.nav-right-div{
    gap: 16px;
}
.logout-div {
    gap: 4px;
}
.logout-icon {
    width: 14px;
    height: 14px;
}
.logout-text {
    width: 70px;
    height: 24px;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #6B7280;
    white-space: nowrap;
}
.help-div{
    gap: 4px;
}
.help-icon{
    width: 14px;
    height: 14px;
}
.help-text{
    width: 41px;
    height: 24px;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #6B7280;
    white-space: nowrap;
}
</style>
